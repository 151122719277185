import { useClassNameConcat } from "@common/hooks";
import { TextArea } from "./TextArea";
import styles from "./Input.module.scss";

export type InputProps = React.HTMLProps<HTMLInputElement> & {
  required: boolean;
  validation?: {
    isErrored: boolean;
    error: string | null;
  };
  className?: string;
};

export type InputType = React.FC<InputProps> & { TextArea: typeof TextArea };

export const Input: InputType = ({
  className,
  required = false,
  validation,
  ...otherProps
}) => {
  const inputStyle = useClassNameConcat([
    styles.root__input,
    className,
    validation?.isErrored ? styles.validation__inputInvalid : "",
  ]);

  return (
    <div>
      <input required={required} className={inputStyle} {...otherProps} />
      {validation && (
        <p className={styles.validation__textInvalid}>{validation.error}</p>
      )}
    </div>
  );
};

Input.TextArea = TextArea;
