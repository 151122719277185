import { InstructorEntity } from "@instructors/types";
import { Dispatch, SetStateAction, createContext } from "react";

export type Modal<T = {}> = {
  isVisible: boolean;
} & T;

export type Modals = {
  mailModal: Modal<{ id: number | null }>;
  messageModal: Modal<{ message: string }>;
  instructorModal: Modal<{ info: InstructorEntity | null }>;
};

type ModalContextType = {
  modals: Modals;
  setModals: Dispatch<SetStateAction<Modals>>;
};

export const defaultValue: ModalContextType = {
  modals: {
    mailModal: {
      isVisible: false,
      id: null,
    },
    messageModal: {
      isVisible: false,
      message: "",
    },
    instructorModal: {
      isVisible: false,
      info: null,
    },
  },
  setModals: () => {},
};

export const ModalContext = createContext<ModalContextType>(defaultValue);
