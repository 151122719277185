import React from "react";
import { Icons } from "../Icon";

export type IconAnchorProps = {
  icon: React.ReactNode;
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
  className?: string;
};

export const IconAnchor: React.FC<IconAnchorProps> = ({
  icon,
  href,
  target = "_blank",
  className,
}) => {
  return (
    <a className={className} href={href} target={target}>
      {icon}
    </a>
  );
};
