import { Flex } from "@common/components";
import { FooterContacts, FooterCopyright, FooterMain } from "./partials";
import styles from "./Footer.module.scss";

export type FooterProps = {};

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Flex className={styles.root} direction="column">
      <Flex className={styles.root__main}>
        <FooterMain />
        <FooterContacts />
      </Flex>
      <FooterCopyright />
    </Flex>
  );
};
