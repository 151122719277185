import { useState } from "react";
import { FCWithChildren } from "@common/types";
import { InstructorEntity, InstructorMapMark } from "@instructors/types";
import { InstructorsContext } from "./Context";
import { AdditionalInstructorInfo } from "@instructors/types/AddtionlaInstructorInfo";

export const InstructorsProvider: FCWithChildren = ({ children }) => {
  const [instructors, setInstructors] = useState<InstructorEntity[]>([]);
  const [mapMarks, setMapMarks] = useState<InstructorMapMark[]>([]);
  const [additionalInfo, setAdditionalInfo] =
    useState<AdditionalInstructorInfo>({
      page: 1,
      showPerPage: 30,
      isAdditionByScrollBlocked: false,
    });

  return (
    <InstructorsContext.Provider
      value={{
        mapMarks,
        instructors,
        additionalInfo,
        setMapMarks,
        setInstructors,
        setAdditionalInfo,
      }}
    >
      {children}
    </InstructorsContext.Provider>
  );
};
