import { useEffect, useRef, useState } from "react";
import { FCWithChildren } from "@common/types";
import { useClassNameMemo } from "@common/hooks";
import styles from "./Collapse.module.scss";

export type CollapseProps = {
  title: string | (() => React.ReactNode);
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  onCollapse?: (isCollpased: boolean) => void;
};

export const Collapse: FCWithChildren<CollapseProps> = ({
  children,
  title,
  className,
  headerClassName: externalHeaderClassName,
  contentClassName: externalContentClassName,
  onCollapse,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const containerClassName = useClassNameMemo([styles.root, className]);
  const headerClassName = useClassNameMemo([
    styles.root__header,
    externalHeaderClassName,
  ]);
  const contentClassName = useClassNameMemo([
    styles.root__content,
    externalContentClassName,
  ]);

  const contentStyles = {
    height: isCollapsed ? "0px" : `${contentRef.current?.scrollHeight}px`,
  };

  useEffect(() => {
    if (onCollapse) {
      onCollapse(isCollapsed);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  return (
    <div className={containerClassName}>
      <div
        className={headerClassName}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {typeof title === "string" ? <p>{title}</p> : title()}
      </div>
      <div ref={contentRef} className={contentClassName} style={contentStyles}>
        {children}
      </div>
    </div>
  );
};
