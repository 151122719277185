import { TldRegion } from "@common/types";
import { CommonConfig } from "./CommonConfig";

const defaultMapCenter = {
  [TldRegion.English]: { lat: 39.758538884236124, lng: -100.39306781249628 },
  [TldRegion.China]: { lat: 36.067911, lng: 101.752442 },
  [TldRegion.Spain]: { lat: 22.825584, lng: -101.535682 },
};

export const MapConfig = {
  defaultMapCenter: defaultMapCenter[CommonConfig.tldRegion],
  defaultMapTileSize: 256,
};
