import { useClassNameMemo } from "@common/hooks";
import styles from "./Dropdown.module.scss";
import { useState } from "react";

export type DropdownProps = {
  title: string | (() => React.ReactNode);
  list: (() => React.ReactNode)[];
  className?: string;
  listClassName?: string;
};

export const Dropdown: React.FC<DropdownProps> = ({
  title,
  list,
  className,
  listClassName: externalListClassName,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const containerClassName = useClassNameMemo([styles.root, className]);

  const listClassName = useClassNameMemo([
    styles.root__list,
    externalListClassName,
  ]);

  return (
    <div
      className={containerClassName}
      onMouseOver={() => setIsDropdownShown(true)}
      onMouseLeave={() => setIsDropdownShown(false)}
    >
      {typeof title === "string" ? (
        <p className={styles.root__title}>{title}</p>
      ) : (
        title()
      )}

      {isDropdownShown && (
        <div className={listClassName}>{list.map((item) => item())}</div>
      )}
    </div>
  );
};
