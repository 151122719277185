import { Flex } from "@common/components/templates";
import styles from "./HeaderList.module.scss";
import { useState } from "react";
import Link from "next/link";

export type HeaderListProps = {
  onClick: () => void;
};

export const HeaderList: React.FC<HeaderListProps> = ({ onClick }) => {
  const [isAboutCollapsed, setIsAboutCollapsed] = useState(true);
  const items = [
    {
      href: "/",
      title: "Certified Instructors",
      isAnchor: false,
    },
    {
      href: "/studios",
      title: "IBBFA Approved Studios",
      isAnchor: false,
    },
    {
      href: "https://barrecertification.com/signup-options",
      title: "Get Certified",
      isAnchor: true,
    },
    {
      href: "https://barrecertification.com/contact-form",
      title: "Contact Us",
      isAnchor: true,
    },
    {
      href: "https://office.barre.directory",
      title: "Log-in",
      isAnchor: true,
    },
  ];

  return (
    <Flex className={styles.root}>
      {items.map((item, i) =>
        item.isAnchor ? (
          <a
            className={styles.root__anchor}
            href={item.href}
            key={i}
            target="_blank"
          >
            {item.title}
          </a>
        ) : (
          <Link
            className={styles.root__anchor}
            href={item.href}
            onClick={onClick}
            key={i}
          >
            {item.title}
          </Link>
        )
      )}
    </Flex>
  );
};
