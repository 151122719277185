import { IconProps } from "../..";

export const MapMark: React.FC<IconProps> = (props) => (
  <svg
    height="15px"
    width="15px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-43.79 -43.79 525.46 525.46"
    fill="#ffffff"
    stroke="#ffffff"
    {...props}
  >
    <g strokeWidth="0" transform="translate(70,60), scale(0.5)">
      <rect
        x="-40"
        y="-40"
        width="525.46"
        height="525.46"
        rx="0"
        fill="#ffffff"
        strokeWidth="0"
      ></rect>
    </g>
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#ffffff"
      strokeWidth="50"
    >
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path
              style={{ fill: "#ff0000" }}
              d="M49.193,0v339.503H155.99l62.949,98.376l62.939-98.376h106.807V0H49.193z M181.94,289.822 c-13.366,0-24.191-4.641-24.191-10.366c0-4.025,5.364-7.464,13.141-9.184V55.504H193v214.768 c7.767,1.729,13.141,5.159,13.141,9.184C206.131,285.181,195.296,289.822,181.94,289.822z M202.565,131.135v0.391h-1.299 L202.565,131.135V55.504l120.339,38.709L202.565,131.135z M202.565,131.545v-0.41l-1.299,0.401L202.565,131.545L202.565,131.545z "
            ></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </g>
    <g>
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path
              style={{ fill: props.fill ?? '#ff0000' }}
              d="M49.193,0v339.503H155.99l62.949,98.376l62.939-98.376h106.807V0H49.193z M181.94,289.822 c-13.366,0-24.191-4.641-24.191-10.366c0-4.025,5.364-7.464,13.141-9.184V55.504H193v214.768 c7.767,1.729,13.141,5.159,13.141,9.184C206.131,285.181,195.296,289.822,181.94,289.822z M202.565,131.135v0.391h-1.299 L202.565,131.135V55.504l120.339,38.709L202.565,131.135z M202.565,131.545v-0.41l-1.299,0.401L202.565,131.545L202.565,131.545z "
            ></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
