import Image from "next/image";
import { MouseEventHandler } from "react";
import { concatClassNames } from "@common/utils";
import styles from "./ImageButton.module.scss";

export type ImageButtonProps = {
  className?: string;
  src: string;
  alt: string;
  fill?: boolean;
  width?: number | `${number}`;
  height?: number | `${number}`;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ImageButton: React.FC<ImageButtonProps> = ({
  src,
  alt,
  fill,
  width,
  height,
  className,
  onClick,
}) => {
  return (
    <button
      className={concatClassNames([styles.root, className])}
      onClick={onClick}
    >
      <Image src={src} alt={alt} fill={fill} width={width} height={height} />
    </button>
  );
};
