import { FormErrors, FormState } from './types';

export const getInitialErrors = <S extends FormState>(
  initialState: S
): FormErrors<S> => {
  const errors: FormErrors<S> = {} as FormErrors<S>;

  for (const key in initialState) {
    errors[key] = {
      isErrored: false,
      error: null,
    };
  }

  return errors;
};
