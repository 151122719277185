import Image from "next/image";
import { HTMLAttributeAnchorTarget, MouseEventHandler } from "react";
import React from "react";

export type ImageAnchorProps = {
  target?: HTMLAttributeAnchorTarget;
  href?: string;
  src: string;
  alt: string;
  fill?: boolean;
  width?: number | `${number}`;
  height?: number | `${number}`;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const ImageAnchor: React.FC<ImageAnchorProps> = React.memo(
  ({ target, href, src, alt, fill, width, height, className, onClick }) => (
    <a className={className} href={href} target={target} onClick={onClick}>
      <Image src={src} alt={alt} fill={fill} width={width} height={height} />
    </a>
  )
);

ImageAnchor.displayName = "ImageAnchor";
