import dynamic from "next/dynamic";
import { useState } from "react";
import { FCWithChildren } from "@common/types";
import { Modals, ModalContext, defaultValue } from "./Context";
import { defaultModalContextValue } from "./Hook";
import { InstructorEntity } from "@instructors/types";

const LazyMailModal = dynamic(() =>
  import("@instructors/components/organisms/MailModal").then(
    (component) => component.MailModal
  )
);

const LazyMessageModal = dynamic(() =>
  import("@instructors/components/molecules/MessageModal").then(
    (component) => component.MessageModal
  )
);

const LazyInstructorModal = dynamic(() =>
  import("@instructors/components/organisms/InstructorModal").then(
    (component) => component.InstructorModal
  )
);

export const ModalsProvider: FCWithChildren = ({ children }) => {
  const [modals, setModals] = useState<Modals>(defaultValue.modals);

  return (
    <ModalContext.Provider value={{ modals, setModals }}>
      {children}

      {modals.mailModal.isVisible && (
        <LazyMailModal
          id={modals.mailModal.id}
          onClose={(isSend: boolean) =>
            isSend
              ? setModals({
                  ...defaultModalContextValue,
                  messageModal: {
                    isVisible: true,
                    message: "Your message is sended!",
                  },
                })
              : setModals(defaultValue.modals)
          }
        />
      )}
      {modals.messageModal.isVisible && (
        <LazyMessageModal
          message={modals.messageModal.message}
          onClose={() => setModals(defaultValue.modals)}
        />
      )}
      {modals.instructorModal.isVisible && (
        <LazyInstructorModal
          {...(modals.instructorModal.info as InstructorEntity)}
        />
      )}
    </ModalContext.Provider>
  );
};
