import { Dispatch, SetStateAction, createContext } from "react";
import { InstructorEntity, InstructorMapMark } from "@instructors/types";
import { AdditionalInstructorInfo } from "@instructors/types/AddtionlaInstructorInfo";

type InstructorsContextType = {
  instructors: InstructorEntity[];
  mapMarks: InstructorMapMark[];
  additionalInfo: AdditionalInstructorInfo;
  setMapMarks: Dispatch<SetStateAction<InstructorMapMark[]>>;
  setInstructors: Dispatch<SetStateAction<InstructorEntity[]>>;
  setAdditionalInfo: Dispatch<SetStateAction<AdditionalInstructorInfo>>;
};

const defaultValue: InstructorsContextType = {
  instructors: [],
  mapMarks: [],
  additionalInfo: {
    page: 1,
    showPerPage: 30,
    isAdditionByScrollBlocked: false,
  },
  setMapMarks: () => {},
  setInstructors: () => {},
  setAdditionalInfo: () => {},
};

export const InstructorsContext = createContext<InstructorsContextType>(defaultValue);
