import { MouseEventHandler } from "react";
import { Icons } from "@common/components";
import styles from "./ScrollUpAffix.module.scss";

export type ScrollUpAffixProps = {
  isVisible: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const ScrollUpAffix: React.FC<ScrollUpAffixProps> = ({
  isVisible,
  onClick,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.root} onClick={onClick}>
      <Icons.ArrowUp className={styles.root__icon} />
    </div>
  );
};
