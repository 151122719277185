import styles from "./FooterCopyright.module.scss";

export type FooterCopyrightProps = {};

export const FooterCopyright: React.FC<FooterCopyrightProps> = () => {
  return (
    <div className={styles.root}>
      © {new Date().getFullYear()} Discover True You, Inc.
    </div>
  );
};
