export * from "./Close";
export * from "./ArrowUp";
export * from "./Menu";
export * from "./MapMark";
export * from "./Instagram";
export * from "./LinkedIn";
export * from "./Telegram";
export * from "./TikTok";
export * from "./Whatsapp";
export * from "./Loader";
export * from "./Logo";
