import { useRef, useState } from "react";
import * as Yup from "yup";
import { getInitialErrors } from "./getInitialErrors";
import { FormErrors, FormState, UseFormParams, UseFormResult } from "./types";

export const useValidationForm = <S extends FormState>(
  params: UseFormParams<S>
): UseFormResult<S> => {
  const {
    initialState,
    schema,
    onSubmit,
    fieldValidators,
    shouldCalculateIsSubmitAvailable = false,
  } = params;
  const mutableStateRef = useRef({ ...initialState });
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [errors, setErrors] = useState(getInitialErrors(initialState));

  const validateField: UseFormResult<S>["validateField"] = async (
    name: keyof S
  ) => {
    const value = mutableStateRef.current[name];

    if (fieldValidators) {
      const validators = fieldValidators[name];

      if (validators) {
        for (let i = 0; i < validators.length; i++) {
          const validator = validators[i];

          if (validator.actions.indexOf("blur") !== -1) {
            const { isErrored, error } = validator.validate(
              value,
              mutableStateRef.current
            );

            setErrors({
              ...errors,
              [name]: {
                isErrored,
                error,
              },
            });

            return;
          }
        }
      }
    }

    let isValid = false;
    let error: string | null = null;

    try {
      // @ts-expect-error
      await schema.fields?.[name].validate(value);

      isValid = true;
      error = null;
    } catch (exception) {
      // @ts-expect-error
      if (exception.name === "ValidationError") {
        const validationError = exception as Yup.ValidationError;
        const { message } = validationError;

        isValid = false;
        error = message;
      }
    }

    setErrors({
      ...errors,
      [name]: {
        isErrored: !isValid,
        error,
      },
    });
  };

  const handleFieldChange: UseFormResult<S>["onFieldChange"] = (
    name,
    value
  ) => {
    mutableStateRef.current[name] = value;
    setRefreshFlag(!refreshFlag);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const validatedState =
        (await schema.validate(mutableStateRef.current, {
          stripUnknown: true,
          abortEarly: false,
        })) || initialState;

      // @ts-expect-error
      onSubmit(validatedState);
    } catch (exception) {
      // @ts-expect-error
      if (exception.name === "ValidationError") {
        const validationError = exception as Yup.ValidationError;
        const filedErrors: Partial<FormErrors<S>> = {};

        for (const error of validationError.inner) {
          filedErrors[error.path as keyof S] = {
            isErrored: true,
            error: error.message,
          };
        }

        setErrors({
          ...errors,
          ...filedErrors,
        });
      }
    }
  };

  const isSubmitAvailable = shouldCalculateIsSubmitAvailable
    ? schema.isValidSync(mutableStateRef.current)
    : false;

  return {
    errors,
    state: { ...mutableStateRef.current },
    isSubmitAvailable,
    validateField,
    onFieldChange: handleFieldChange,
    onSubmit: handleSubmit,
  };
};
