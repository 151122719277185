import {
  ArrowUp,
  Close,
  Instagram,
  LinkedIn,
  Loader,
  MapMark,
  Menu,
  Telegram,
  TikTok,
  Whatsapp,
  Logo
} from "./Icons";

export type IconProps = React.SVGProps<SVGSVGElement>;

export type IconComponentProps = {
  ArrowUp: typeof ArrowUp;
  Close: typeof Close;
  Menu: typeof Menu;
  MapMark: typeof MapMark;
  Whatsapp: typeof Whatsapp;
  Telegram: typeof Telegram;
  LinkedIn: typeof LinkedIn;
  TikTok: typeof TikTok;
  Instagram: typeof Instagram;
  Loader: typeof Loader;
};

export const Icons: IconComponentProps = {
  ArrowUp,
  Close,
  Menu,
  MapMark,
  Instagram,
  Telegram,
  TikTok,
  LinkedIn,
  Whatsapp,
  Loader,
};
