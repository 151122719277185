import { Flex, ImageAnchor } from "@common/components";
import styles from "./FooterContacts.module.scss";
import { ImagesPaths } from "@common/configs/ImageConfig";

export type FooterContactsProps = {};

export const FooterContacts: React.FC<FooterContactsProps> = () => {
  return (
    <Flex className={styles.root} direction="column">
      <ImageAnchor
        className={styles.root__image}
        src={ImagesPaths.ibbfa}
        alt="ibbfa logo"
        href="https://ibbfa.org"
        width={70}
        height={70}
      />
    </Flex>
  );
};
