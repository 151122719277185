import { IInstructor } from "@instructors/interfaces";
import { InstructorEntity } from "@instructors/types";

export const castInstructorToInstructorEntity = (
  instructor: IInstructor
): InstructorEntity => ({
  id: instructor.instructor ? instructor.instructor.id : null,
  fullName: `${instructor.first_name} ${instructor.last_name}`,
  avatarPath: instructor.instructor ? instructor.instructor.logo_path : null,
  isActive: instructor.instructor ? !instructor.instructor.inactive : null,
  cardTagType: instructor.instructor ? instructor.instructor.class_name : null,
  ribbonType: instructor.instructor ? instructor.instructor.cert_name : null,
  cooridanates: instructor.instructor
    ? {
      lat: instructor.instructor.lat,
      lng: instructor.instructor.lng,
    }
    : null,
  coursesStatuses: instructor.instructor
    ? {
      1: Boolean(instructor.instructor.certificate.l1),
      2: Boolean(instructor.instructor.certificate.l2),
      3: Boolean(instructor.instructor.certificate.l3),
      4: Boolean(instructor.instructor.certificate.pa),
      5: Boolean(instructor.instructor.certificate.st),
    }
    : null,
  certificateNumber: instructor.instructor
    ? instructor.instructor.certificate.cert_no
    : null,
  certificateDate: instructor.instructor
    ? instructor.instructor.activation_date_format
    : null,
  certificateExpireDate: instructor.instructor
    ? instructor.instructor.end_date_format
    : null,
  location: `${instructor.city ? `${instructor.city},` : ""} ${instructor.state ? `${instructor.state},` : ""
    } ${instructor.country ? `${instructor.country},` : ""}`,
  address: instructor.address1,
  company: instructor.company,
  description: instructor.instructor?.desc1
    ? instructor.instructor.desc1
    : null,
  links:
    Boolean(instructor.instructor?.instagram) ||
      Boolean(instructor.instructor?.telegram) ||
      Boolean(instructor.instructor?.linkedin) ||
      Boolean(instructor.instructor?.tik_tok) ||
      Boolean(instructor.instructor?.whatsapp)
      ? {
        telegram: instructor.instructor?.telegram,
        whatsapp: instructor.instructor?.whatsapp,
        linkedin: instructor.instructor?.linkedin,
        tik_tok: instructor.instructor?.tik_tok,
        instagram: instructor.instructor?.instagram,
      }
      : null,
  studio: instructor.instructor ? instructor.instructor.studio : null,
  enabled: instructor.instructor ? (instructor.instructor.activation_date ? true : false) : false,
});
