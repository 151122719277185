import { useState } from "react";
import { FCWithChildren } from "@common/types";
import { InstructorMapMark } from "@instructors/types";
import { StudiosContext } from "./Context";
import { IStudio } from "../../interfaces";

export const StudiosProvider: FCWithChildren = ({ children }) => {
  const [studios, setStudios] = useState<IStudio[]>([]);
  const [mapMarks, setMapMarks] = useState<InstructorMapMark[]>([]);

  return (
    <StudiosContext.Provider
      value={{
        mapMarks,
        studios,
        setMapMarks,
        setStudios,
      }}
    >
      {children}
    </StudiosContext.Provider>
  );
};
