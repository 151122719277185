import {
  InstructorsProvider,
  ModalsProvider,
} from "@instructors/context";
import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { Page } from "@common/components";
import { StudiosProvider } from "@studios/context";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <InstructorsProvider>
      <StudiosProvider>
        <ModalsProvider>
          <Page>
            <Component {...pageProps} />
          </Page>
        </ModalsProvider>
      </StudiosProvider>
    </InstructorsProvider>
  );
}

export default MyApp;
