import styles from "./Flex.module.scss";
import { useClassNameMemo } from "@common/hooks";
import { forwardRef } from "react";

export type FlexProps = React.HTMLProps<HTMLDivElement> & {
  id?: string;
  className?: string;
  direction?: "row" | "column";
  children: React.ReactNode;
};

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ id, children, className, direction = "row", ...otherProps }, ref) => {
    const containerClassName = useClassNameMemo([
      styles.root,
      styles[direction],
      className,
    ]);

    return (
      <section ref={ref} id={id} className={containerClassName} {...otherProps}>
        {children}
      </section>
    );
  }
);

Flex.displayName = "Flex";
