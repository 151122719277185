import { TldRegion } from "@common/types";

const tldRegionObj: Record<string, TldRegion> = {
  [TldRegion.English]: TldRegion.English,
  [TldRegion.Spain]: TldRegion.Spain,
  [TldRegion.China]: TldRegion.China,
};

export const getUrlTld = (): { realTld: string; tldRegion: TldRegion } => {
  if (typeof window !== undefined && Boolean(globalThis.window)) {
    const tld = window.location.origin.split(".").pop() as string;

    if (!tldRegionObj[tld]) {
      return { realTld: tld, tldRegion: TldRegion.English };
    }

    if (tld === TldRegion.China) {
      return { realTld: tld, tldRegion: TldRegion.Spain };
    }

    return { realTld: tld, tldRegion: tld as TldRegion };
  }

  return { realTld: "", tldRegion: TldRegion.English };
};
