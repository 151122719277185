import { useState } from "react";
import { Flex } from "@common/components/templates";
import { useBreakpoints } from "@common/hooks";
import { Breakpoint } from "@common/types";
import { HeaderLine, HeaderList } from "./partials";
import { Icons } from "@common/components/atoms";
import styles from "./Header.module.scss";
import { useRouter } from "next/router";
import { Logo } from "@common/components/atoms/Icon/Icons";

export type HeaderProps = {};

export const Header: React.FC<HeaderProps> = () => {
  const [isListShown, setIsListShown] = useState(false);
  const router = useRouter();

  const breakpoint = useBreakpoints();

  if (breakpoint < Breakpoint.LG) {
    return (
      <Flex className={styles.root}>
        <div className={styles.head}>
          <Logo
            onClick={() => router.push("/")}
            className={styles.head__logo}
            width={180}
            height={60}
          />

          <Icons.Menu
            className={styles.head__menu}
            onClick={() => setIsListShown(!isListShown)}
          />
        </div>

        {isListShown && (
          <HeaderList onClick={() => setIsListShown(!isListShown)} />
        )}
      </Flex>
    );
  }

  return (
    <Flex className={styles.root}>
      {breakpoint >= Breakpoint.XXL ? (
        <Logo
          onClick={() => router.push("/")}
          className={styles.head__logo}
          width={500}
          height={100}
        />
      ) : (
        <Logo onClick={() => router.push("/")} className={styles.head__logo} />
      )}
      <HeaderLine />
    </Flex>
  );
};
