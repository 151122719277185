import { useMemo } from "react";
import { concatClassNames } from "../utils";
import { ClassNameType } from "../types";

export const useClassNameMemo = (classNames: ClassNameType[]): string => {
  return useMemo(
    () => concatClassNames(classNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classNames]
  );
};
