import { useState } from "react";

type useFormProps<T, K extends string, V> = {
  initialState: Record<K, V>;
  submit: (state: T) => void;
};

type useFormReturns<K, V, T> = {
  formState: T;
  onFieldChange: (field: K, value: V) => void;
  onSubmit: () => void;
};

export const useForm = <K extends string, V>({
  initialState,
  submit,
}: useFormProps<Record<K, V>, K, V>): useFormReturns<K, V, Record<K, V>> => {
  const [formState, setFormState] = useState<Record<K, V>>(initialState);

  const onFieldChange = (field: K, value: V) => {
    setFormState({ ...formState, [field]: value });
  };

  const onSubmit = () => {
    submit(formState);
  };

  return { formState, onFieldChange, onSubmit };
};
