import { MouseEventHandler } from "react";
import { FormLine, FormLineProps } from "../../molecules";
import { useClassNameMemo } from "../../../hooks";
import styles from "./Form.module.scss";

export type FormOptions = {
  title?: {
    text: string;
    className?: string;
  };
  fields: FormLineProps[];
  submitButton: {
    title: string;
    className?: string;
    available?: boolean;
    onSubmit: MouseEventHandler<HTMLButtonElement>;
  };
  submitError?: string | null;
};

export type FormProps = {
  formOptions: FormOptions;
  className?: string;
};

export const Form: React.FC<FormProps> = ({ formOptions, className }) => {
  const formClassName = useClassNameMemo([styles.root, className]);

  const titleClassName = useClassNameMemo([
    styles.root__title,
    formOptions.title?.className,
  ]);

  const buttonClassName = useClassNameMemo([
    styles.button,
    formOptions.submitButton.className,
    typeof formOptions.submitButton.available === "undefined" ||
    formOptions.submitButton.available
      ? styles.button__available
      : styles.button__unavailable,
  ]);

  return (
    <div className={formClassName}>
      {formOptions.title && (
        <h1 className={titleClassName}>{formOptions.title.text}</h1>
      )}

      <div className={styles.root__lines}>
        {formOptions.fields.map((field) => (
          <FormLine key={field.inputOptions.name} {...field} />
        ))}
      </div>

      <button
        className={buttonClassName}
        aria-label="Submit search fields"
        onClick={formOptions.submitButton.onSubmit}
      >
        {formOptions.submitButton.title}
      </button>

      {formOptions.submitError && (
        <p className={styles.error__text}>
          {formOptions.submitError ||
            "Something went wrong. Please contact support"}
        </p>
      )}
    </div>
  );
};
