import dynamic from "next/dynamic";
import { Header } from "@common/components";
import { FCWithChildren } from "@common/types";
import { useScrollVertical } from "@common/hooks";
import styles from "./Page.module.scss";

const LazyFooter = dynamic(() =>
  import("@common/components/organisms/Footer").then(
    (component) => component.Footer
  )
);

export type PageProps = {};

export const Page: FCWithChildren<PageProps> = ({ children }) => {
  const scrollDirection = useScrollVertical();

  return (
    <div className={styles.root}>
      <header
        style={{
          top: scrollDirection === "up" ? "-1px" : "-100%",
        }}
        className={styles.root__header}
      >
        <Header />
      </header>

      <main className={styles.root__main}>{children}</main>

      <footer className={styles.root__footer}>
        <LazyFooter />
      </footer>
    </div>
  );
};
