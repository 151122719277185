import { MapConfig } from "@common/configs/MapConfig";
import { InstructorMapMark } from "@instructors/types";
import { latLng2Scaled } from "@instructors/utils";

export type DrawMarkerIconFuncType = ({
  context,
  x,
  y,
}: {
  context: CanvasRenderingContext2D;
  x: number;
  y: number;
}) => void;

type DrawMarkerIconProps = {
  markers: InstructorMapMark[];
  zoom: number;
  tileX: number;
  tileY: number;
  drawMarkerIcon: DrawMarkerIconFuncType;
};

const TILE_SIZE = MapConfig.defaultMapTileSize;

export const drawMarkerIcon = async (
  context: CanvasRenderingContext2D,
  { markers, zoom, tileX, tileY, drawMarkerIcon }: DrawMarkerIconProps
) => {
  await context.clearRect(0, 0, TILE_SIZE, TILE_SIZE);

  await markers.forEach((marker) => {
    const pointTile = latLng2Scaled(marker, zoom);
    const point = {
      x: (pointTile.x - tileX) * TILE_SIZE,
      y: (pointTile.y - tileY) * TILE_SIZE,
    };

    drawMarkerIcon({ context, x: point.x, y: point.y });
  });
};

export const drawMarkerIconEffect = async (
  canvasRef: HTMLCanvasElement,
  props: Omit<DrawMarkerIconProps, "context">
) => {
  const context = canvasRef.getContext("2d");

  if (context) {
    await drawMarkerIcon(context, props);
  }
};
