export const ImagesPaths = {
  ibbfa: "/img/ibbfa.webp",
  logo: "/img/logo.webp",
  avatarPlaceholder: "/img/avatar-placeholder.webp",
  studioPlaceholder: "/img/studio-placeholder.png",
  location: "/img/location.webp",
  mail: "/img/mail.webp",
  phone: "/img/phone.webp",
  ribbon: {
    Diamond: "/img/ribbon-diamond.webp",
    Gold: "/img/ribbon-gold.webp",
    Silver: "/img/ribbon-silver.webp",
  },
  tag: {
    diamond: "/img/tag-diamond.webp",
    gold: "/img/tag-gold.webp",
    silver: "/img/tag-silver.webp",
  },
  courses: {
    header: "/img/courses-header.webp",
    1: "/img/course1.webp",
    2: "/img/course2.webp",
    3: "/img/course3.webp",
    4: "/img/course4.webp",
    5: "/img/course5.webp",
  },
};
