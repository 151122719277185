import { Flex } from "@common/components/templates";
import styles from "./FooterMain.module.scss";

export type FooterMainProps = {};

export const FooterMain: React.FC<FooterMainProps> = () => {
  return (
    <Flex className={styles.root} direction="column">
      <a
        className={styles.root__anchor}
        href="https://barrecertification.com/terms-privacy"
      >
        Privacy Policy
      </a>
      <a
        className={styles.root__anchor}
        href="https://barrecertification.com/terms-of-use"
      >
        Terms of use
      </a>
    </Flex>
  );
};
