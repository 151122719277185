import { MouseEventHandler, useEffect } from "react";
import { FCWithChildren } from "@common/types";
import { Flex } from "../Flex";
import { concatClassNames } from "@common/utils";
import styles from "./Modal.module.scss";

export type ModalProps = {
  className?: string;
  contentClassName?: string;
  backdrop?: {
    className?: string;
    isVisible: boolean;
  };
  onClose: MouseEventHandler<HTMLDivElement>;
};

export const Modal: FCWithChildren<ModalProps> = ({
  children,
  className,
  contentClassName,
  onClose,
  backdrop = {
    className: undefined,
    isVisible: false,
  },
}) => {
  useEffect(() => {
    const internalOnClose = (e: KeyboardEvent) => {
      // if pressed esc then close
      if (e.keyCode === 27) {
        onClose(e as any);
      }
    };

    window.addEventListener("keydown", internalOnClose);

    return () => window.removeEventListener("keydown", internalOnClose);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex className={concatClassNames([styles.root, className])}>
      {backdrop?.isVisible && (
        <div
          className={concatClassNames([
            styles.root__backdrop,
            backdrop.className,
          ])}
          onClick={onClose}
        />
      )}
      <div
        className={concatClassNames([styles.root__content, contentClassName])}
      >
        {children}
      </div>
    </Flex>
  );
};
