import { Input, InputProps } from "../../atoms";
import { useClassNameMemo } from "../../../hooks";
import { Flex } from "@common/components";
import { TextAreaProps } from "@common/components/atoms/Input/TextArea";
import styles from "./FormLine.module.scss";

export type FormLineProps = {
  labelOptions: { title: string; className?: string };
  inputOptions: InputProps | TextAreaProps;
  className?: string;
};

export const FormLine: React.FC<FormLineProps> = ({
  inputOptions: {
    className: inputOptionsClassName,
    type: inputType,
    ...otherInputOptions
  },
  labelOptions,
  className,
}) => {
  const containerClassName = useClassNameMemo([styles.root, className]);
  const labelClassName = useClassNameMemo([
    styles.root__label,
    labelOptions.className,
  ]);
  const inputClassName = useClassNameMemo([
    styles.root__input,
    inputOptionsClassName,
  ]);

  return (
    <Flex className={containerClassName}>
      <label htmlFor={otherInputOptions.name} className={labelClassName}>
        {labelOptions.title}
      </label>
      {inputType === "textarea" ? (
        <Input.TextArea
          id={otherInputOptions.name}
          className={inputClassName}
          {...(otherInputOptions as TextAreaProps)}
        />
      ) : (
        <Input
          id={otherInputOptions.name}
          className={inputClassName}
          {...(otherInputOptions as InputProps)}
        />
      )}
    </Flex>
  );
};
