/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getWindowWidth } from "@common/utils";
import { Breakpoint } from "@common/types";

const BreakpointsArr = [
  Breakpoint.DEFAULT,
  Breakpoint.XS,
  Breakpoint.SM,
  Breakpoint.MD,
  Breakpoint.LG,
  Breakpoint.XL,
  Breakpoint.XXL,
];

export const useBreakpoints = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState(Breakpoint.DEFAULT);

  useEffect(() => {
    const changeBreakpoint = () => {
      const windowWidth = getWindowWidth();
      let finalBreakpoint = Breakpoint.DEFAULT;

      BreakpointsArr.map((value) => {
        if (windowWidth >= value) {
          finalBreakpoint = value;
        }
      });

      setBreakpoint(finalBreakpoint);
    };

    if (typeof window !== undefined) {
      window.addEventListener("resize", changeBreakpoint, { passive: true });
    }

    changeBreakpoint();

    return () => window.removeEventListener("resize", changeBreakpoint);
  }, []);

  return breakpoint;
};
