import { Dispatch, SetStateAction, createContext } from "react";
import { InstructorMapMark } from "@instructors/types";
import { IStudio } from "@studios/interfaces";

type StudiosContextType = {
  studios: IStudio[];
  mapMarks: InstructorMapMark[];
  setMapMarks: Dispatch<SetStateAction<InstructorMapMark[]>>;
  setStudios: Dispatch<SetStateAction<IStudio[]>>;
};

const defaultValue: StudiosContextType = {
  studios: [],
  mapMarks: [],
  setMapMarks: () => {},
  setStudios: () => {},
};

export const StudiosContext = createContext<StudiosContextType>(defaultValue);
