import { useClassNameMemo } from "@common/hooks";
import styles from "./Input.module.scss";

export type TextAreaProps = React.HTMLProps<HTMLTextAreaElement> & {
  className?: string;
  validation?: {
    isErrored: boolean;
    error: string | null;
  };
};

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  validation,
  ...otherProps
}) => {
  const textAreaStyle = useClassNameMemo([
    styles.root,
    className,
    validation?.isErrored ? styles.validation__inputInvalid : "",
  ]);

  return (
    <div>
      <textarea className={textAreaStyle} {...otherProps} />
      {validation && (
        <p className={styles.validation__textInvalid}>{validation.error}</p>
      )}
    </div>
  );
};
