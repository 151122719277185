import { useEffect, useRef } from "react";
import { MapConfig } from "@common/configs/MapConfig";
import { InstructorMapMark } from "@instructors/types";
import { DrawMarkerIconFuncType, drawMarkerIconEffect } from "./drawMarkerIcon";

export type MapTileProps = {
  markers: InstructorMapMark[];
  zoom: number;
  lat: number;
  lng: number;
  x: number;
  y: number;
  drawMarkerIcon: DrawMarkerIconFuncType;
};

export const MapTile: React.FC<MapTileProps> = ({
  markers,
  x: tileX,
  y: tileY,
  zoom = 4,
  drawMarkerIcon,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      drawMarkerIconEffect(canvasRef.current, {
        markers,
        tileX,
        tileY,
        zoom,
        drawMarkerIcon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <canvas
      width={MapConfig.defaultMapTileSize}
      height={MapConfig.defaultMapTileSize}
      ref={canvasRef}
    />
  );
};
